<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-2 col-md-offset-2"></div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-6 text-center">
                            <img class="comodo-ssl" src="../assets/images/comodo-ssl.svg" />
                        </div>
                        <div class="col-md-6 text-center">
                            <div>Desenvolvido com Tecnologia</div>
                            <a href="#" target="_blank" class="logo-rocket">
                                <img src="@/assets/logo/logo-rocket.png" alt="FM Rocket" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-md-offset-2"></div>
            </div>
            <div class="row copyright">
                <div class="col-md-12 text-center">
                    <strong> Copyright © {{ year }} Invest Proteção Veicular - Todos os direitos reservados. </strong>
                    <p class="mb-0">
                        A Invest Proteção Veicular é uma associação de proteção colaborativa com objetivo de proporcionar ao seus associados uma proteção
                        completa.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        mounted() {},
        data() {
            return {
                year: new Date().getFullYear(),
            };
        },
    };
</script>

<style scoped>
    footer {
        font-size: 10px;
        background: var(--background-footer);
        color: white !important;
        width: 100%;
        padding: 2.2rem 0;
        position: absolute;
        bottom: 0;
    }

    img {
        width: inherit;
        height: inherit;
    }

    .comodo-ssl {
        width: 120px;
        height: 45px;
    }

    .copyright {
        margin-top: 1.5rem;
    }

    .numero {
        white-space: nowrap;
        font-size: 16px;
        margin-bottom: -10px;
    }

    .logo-rocket {
        display: flex;
        justify-content: center;
    }

    .logo-rocket img {
        height: 24px;
    }

    @media (max-width: 1200px) {
        footer {
            position: unset;
            margin-top: 5.8rem;
        }
    }
</style>
