import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

import Veiculo from "@/pages/Veiculos";
import Cliente from "@/pages/Cliente";
import Cotacao from "@/pages/Cotacao";
import Fechamento from "@/pages/Fechamento";
import Login from "@/pages/Login";
import Home from "@/pages/painel/Home";
import Planos from "@/pages/painel/Planos";
import Faixas from "@/pages/painel/Faixas";
import Opcionais from "@/pages/painel/Opcionais";
import Usuarios from "@/pages/painel/Usuarios";
import VeiculosEspeciais from "@/pages/painel/VeiculosEspeciais";

const routes = [
    {
        path: "/:usuario_hash?/simulacao",
        meta: {
            header: true,
        },
        name: 'simulacao',
        component: Veiculo,
    },
    {
        path: "/:usuario_hash?/cliente",
        meta: {
            header: true,
        },
        name: "cliente",
        component: Cliente,
    },
    {
        path: "/:usuario_hash?/cotacao",
        meta: {
            header: true,
        },
        name: "cotacao",
        component: Cotacao,
    },
    {
        path: "/:usuario_hash?/fechamento",
        meta: {
            header: true,
        },
        name: "fechamento",
        component: Fechamento,
    },
    {
        path: "/login",
        name: "login",
        meta: {
            navbar: true,
        },
        component: Login,
    },
    {
        path: "/home",
        name: "home",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: Home,
    },
    {
        path: "/planos",
        name: "planos",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: Planos,
    },
    {
        path: "/faixas",
        name: "faixas",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: Faixas,
    },
    {
        path: "/opcionais",
        name: "opcionais",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: Opcionais,
    },
    {
        path: "/usuarios",
        name: "usuarios",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: Usuarios,
    },
    {
        path: "/veiculos-especiais",
        name: "veiculos-especiais",
        meta: {
            requerLogin: true,
            navbar: true,
        },
        component: VeiculosEspeciais,
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(route => route.meta.requerLogin)) {
        if (store.state.auth.logged) {
            next();
        } else {
            await store.dispatch("loadUsuarioLogado").then(() => {
                if (store.state.auth.logged) {
                    next();
                } else {
                    next("/login");
                }
            });
        }
    } else {
        next();
    }
});

export default router;
