<template>
    <div>
        <b-navbar toggleable="lg" type="dark">
            <b-navbar-brand href="#">Invest</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse v-if="authenticated" id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <!-- <b-nav-item :active="checkActive('home')" @click="navigate('home')" href="#">Principal</b-nav-item> -->
                    <b-nav-item :active="checkActive('planos')" @click="navigate('planos')" href="#">Planos</b-nav-item>
                    <b-nav-item :active="checkActive('faixas')" @click="navigate('faixas')" href="#">Faixas</b-nav-item>
                    <b-nav-item :active="checkActive('opcionais')" @click="navigate('opcionais')" href="#">Opcionais</b-nav-item>
                    <b-nav-item :active="checkActive('veiculos-especiais')" @click="navigate('veiculos-especiais')" href="#">Veículos Especiais</b-nav-item>
                    <b-nav-item :active="checkActive('usuarios')" @click="navigate('usuarios')" href="#">Usuários</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="logout">
                        Sair
                        <b-icon icon="power" />
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import usuario from "@/services/usuario";

    export default {
        name: "Navbar",
        computed: {
            active() {
                return this.$route.name;
            },
            authenticated() {
                return this.$store.state.auth.logged;
            },
        },
        methods: {
            checkActive(routeName) {
                return routeName === this.active;
            },
            navigate(to) {
                this.$router.push({ name: to });
            },
            async logout() {
                await usuario.logout().then(() => this.$router.push({ name: "simulacao" }));
            },
        },
    };
</script>

<style scoped>
    .navbar {
        z-index: 4;
        background-color: var(--background-primary);
    }
</style>
